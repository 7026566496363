import React, {useContext} from "react"
import {useState} from 'react'
import {useHTTP} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import {useHistory} from "react-router-dom";

// Страница создания новых ссылок
// Представляет из себя 1 input, при нажатии на Enter отправляет на сервер api/link/generate ссылку, где она кодируется и заносится в базу

export const CreatePage = () => {
  // Наш кастомных хук для запросов на сервер
  const {request} = useHTTP();
  // Стейт для ссылки
  const [link, setLink] = useState("")
  const auth = useContext(AuthContext);
  // Чтобы мы могли воспользоваться переадресацией и после сохранения нашей ссылки и прихода ответа от сервера переадресовать пользователя на страницу со ссылкой
  const history = useHistory();

  // Отправка не сервер происходит при нажатии Enter, это его обработчик. Поскольку мы ссылку должны сохранить, то все должно быть асинхронно
  const keyHandler = async e => {
    if (e.key === 'Enter') {
      try {

        // Отправляем данные которые нужны нашей Model link
        // Шаблон распознавания этого header у нас в 'api/link/generate', Bearer добавляем видимо для безопасности

        const data = await request('api/link/generate', 'POST', {from: link}, {authorization: `Bearer ${auth.token}`})
        history.push(`/detail/${data.link._id}`)

      } catch (e) {
      }

    }
  }

  const clickHandler = async () => {
    try {

      // Отправляем данные которые нужны нашей Model link
      // Шаблон распознавания этого header у нас в 'api/link/generate', Bearer добавляем видимо для безопасности

      const data = await request('api/link/generate', 'POST', {from: link}, {authorization: `Bearer ${auth.token}`})
      history.push(`/detail/${data.link._id}`)

    } catch (e) {
    }

  }

  const disabled = link.length > 0 ? false : true;

  return (
    <div className="row">
      <div className="col s8 offset-s2" style={{marginBlock: '4rem'}}>
        <div className="input-field">
          <input
            placeholder="Enter your link"
            id="link"
            type="text"
            value={link}
            onChange={e => setLink(e.target.value)}
            onKeyPress={keyHandler}
          />
          <label className="active" htmlFor="link">Enter link</label>
        </div>

        <button
          className='waves-effect waves-light btn'
          disabled={disabled}
          onClick={clickHandler}
        >
          Create
        </button>
      </div>
    </div>
  )
}