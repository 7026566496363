import React, {useContext} from "react"
import {NavLink} from "react-router-dom";
import {AuthContext} from "../context/AuthContext";

export const Navbar = () => {
  const {logout} = useContext(AuthContext)

  const logoutHandler = (e) => {
    e.preventDefault()
    logout()
    // Перемещение на любую страницу
    document.location.href = "/";
  }

  return (
    <nav>
      <div className="nav-wrapper blue darken-1" style={{paddingInline: "2rem"}}>
        <span className="brand-logo">Link's shorter</span>
        <ul id="nav-mobile" className="right hide-on-med-and-down">
          <li><NavLink to="/links">Links</NavLink></li>
          <li><NavLink to="/create">Create page</NavLink></li>
          {/*eslint-disable-next-line*/}
          <li><a role="button" onClick={logoutHandler}>Logout</a></li>
        </ul>
      </div>
    </nav>
  )
}