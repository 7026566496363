import React from "react";

// функция, которая ничего не возвращает. Нужна, чтобы login и logout были фунциями
function noop() {}

// Контекст используем для передачи не по древовидной структуре, а конкретному элементу или всему приложению
export const AuthContext = React.createContext({
  token: null,
  userId: null,
  login: noop,
  logout: noop,
  isAuthenticated: false
})