import React from "react"
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {CreatePage} from "./pages/CreatePage";
import {DetailPage} from "./pages/DetailPage";
import {LinksPage} from "./pages/LinksPage";
import {AuthPage} from "./pages/AuthPage";

// Определяем набор страниц

export const Routes = ({isAuth}) => {
  //Если прошла авторизяиция (пользователь имеет jwt)
  if( !!isAuth ){
    return (
      <Switch>
        <Route path="/create" exact>
          <CreatePage />
        </Route>
        <Route path="/links" exact>
          <LinksPage />
        </Route>
        <Route path="/detail/:id">
          <DetailPage />
        </Route>
        <Redirect to={"/create"} />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/" exact>
        <AuthPage />
      </Route>
      <Redirect to={"/"} />
    </Switch>
  )

}