import React, {useContext, useEffect, useState} from "react"
import {useHTTP} from "../hooks/http.hook";
import {ShowMessage} from "../hooks/showMessage.hook";
import {AuthContext} from "../context/AuthContext";
import {Loader} from "../components/Loader";


export const AuthPage = () => {

  const {loading, error, request, clearError} = useHTTP()
  const message = ShowMessage()

  // Получаем Context с token, userId, login, logout и isAuthenticated
  const auth = useContext(AuthContext)

  const [form, setForm] = useState({email: '', password: ''})

  const changeHandler = event => {
    //Вешаем этот обработчик на input и при изменении Input он обновляет стейт
    // [event.target.name] - диначимеский ключ https://habr.com/ru/company/ruvds/blog/414377/
    setForm({...form, [event.target.name]: event.target.value})
  }

  // Показ ошибок
  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const registerHandler = async () => {
    try {
      const data = await request('/api/auth/register', "POST", {...form})
      message(data.message)

    } catch (e) {

    }
  }

  const loginHandler = async () => {
    try {
      const date = await request('/api/auth/login', "POST", {...form})
      auth.login(date.token, date.userId)

    } catch (e) {

    }
  }

  return (
    <>
      {loading && <Loader />}

      <div className="row">
        <div className="col s12 m6">
          <h1>Short my link</h1>
          <div className="card blue darken-1">
            <div className="card-content white-text">
              <span className="card-title">Authorization</span>

              <div className="input-field">
                <input
                  placeholder="Enter email"
                  id="email"
                  type="text"
                  className="input-hover-yellow"
                  name="email"
                  value={form.email}
                  onChange={changeHandler}
                />
                <label className="active" htmlFor="email">Enter email</label>
              </div>

              <div className="input-field">
                <input
                  placeholder="Enter password"
                  id="password"
                  type="password"
                  className="input-hover-yellow"
                  name="password"
                  value={form.password}
                  onChange={changeHandler}
                />
                <label className="active" htmlFor="password">Enter password</label>
              </div>

              <div>
                {/*eslint-disable-next-line*/}
                <a role="button" className="btn yellow darken-4 mr-1" onClick={loginHandler}
                   aria-disabled={loading}>Enter</a>
                {/*eslint-disable-next-line*/}
                <a role="button" className="btn grey lighten-1 black-text" onClick={registerHandler}
                   aria-disabled={loading}>Register</a>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>



  )
}