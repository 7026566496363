import React from "react"

export const LinkCard = ({link}) => {
  return (
    <>
      <h1>Your link</h1>
      <p>Your link: <a href={link.to} rel="noopener nofollow noreferrer" target="_blank">{link.to}</a></p>
      <p>From: <a href={link.from} rel="noopener nofollow noreferrer" target="_blank">{link.from}</a></p>
      <p>Clicks by link: <strong>{link.clicks}</strong></p>
      <p>Link was created: <strong>{new Date(link.date).toLocaleDateString()}</strong></p>
    </>
  )
}