import {useCallback, useEffect, useState} from "react";

export const LoginHook = () => {
  const storageName = 'storage'
  // Чтобы при logout или login у нас происходил перерендер страницы, мы используем state
  const [token, setToken] = useState(null)
  // Авторизация использует useEffect, который является асинхронным и он не успевает отрабатывать во время загрузки приложения, поэтому всегда грузится CreatPage по умолчанию
  // Поэтому используем этот флаг, чтобы понять когда useEffect отработал
  const [ready, setReady] = useState(null)
  const [userId, setUserId] = useState(null)


  // Положить данные в LocalStorage
  // Аргументы не обязательно принимать на верхнем уровне, можно их принять внутри метода
  const login = useCallback((jwtToken, id) => {
    // Это те же token и userId, но мы их принимаем в качетстве аргументов метода, поэтому называем по другому, чтобы не было путанницы
    setToken(jwtToken)
    setUserId(id)

    // В localStorage добавляем данные из state
    localStorage.setItem(storageName, JSON.stringify({token: jwtToken, userId: id}));
  }, [])

  // Удалить данные из LocalStorage
  const logout = useCallback(() => {
    setToken(null)
    setUserId(null)
    localStorage.removeItem(storageName)
  }, [])

  // При загрузке страницы проверить есть ли данные в LocalStorage и залогиниться
  // Мы сразу вызываем этот метод, поэтому он идет без отдельной переменной
  // мы используем тут метод login, поэтому выставляем его как зависимость и именно поэтому login обернут в useCallback, в иначе начнется рекурсия
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName))

    // Если эти данные есть в localStorage, то автологинимся
    // Если data нет то все выражение выдает ошибку, поэтому первой проверкой ставим !!data
    if (!!data && !!data.token) {
      login(data.token, data.userId)
    }

    setReady(true)
  }, [login])


  return {login, logout, token, userId, ready}
}