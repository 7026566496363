import {useCallback, useState} from "react";

export const useHTTP = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // useCallback исползуем чтобы React не входил в рекурсию
  // функция
  const request = useCallback(async (url, method = "GET", body = null, headers = {}) => {
    // Поскольку функция у нас асинхронная, то всегда добавляем try-catch
    try {

      // При отправке запроса включаем loading
      setLoading(true)

      //На сервер нужно отправлять все данные в формате JSON, иначе будет приходить простой объект (на node.js также выполняется настройка express для принятия JSON)
      if (!!body){
        body = JSON.stringify(body)

        // В случае, если body есть, то и заголовки необходимо передавать что мы передаем JSON
        headers['Content-Type'] = 'application/json;charset=utf-8'
        headers['accepts'] = 'application/json'
      }

      // https://learn.javascript.ru/fetch
      const response = await fetch(url, {method, headers, body})
      const data = await response.json()
      if(!response.ok){
        throw new Error(data.message || "Something went wrong")
      }

      // Если ошибки нет и мы прошли сюда, то отключаем loading
      setLoading(false)

      return data
    } catch (e) {
      //Запрос отработал, поэтому loading убираем
      setLoading(false)
      // Ошибка сюда попадает сверху, из блока try, и именно там мы определяем и получаем message.
      setError(e.message)
      // Команда выбрасывания ошибки
      throw e
    }

  },[])

  // Функция очистки ошибок, пока непонятно зачем она нужна
  const clearError = useCallback(() => setError(null), [])

  return {loading, request, error, clearError}
}