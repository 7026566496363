import React, {useCallback, useContext, useEffect, useState} from "react"
import {useHTTP} from "../hooks/http.hook";
import {Loader} from "../components/Loader";
import {AuthContext} from "../context/AuthContext";
import {LinksList} from "../components/LinksList";

// Страница выводит список всех ссылок определенного пользователя
export const LinksPage = () => {
  // Все общение идем через стейт, чтобы страница обновлялась
  const [links, setLinks] = useState([])

  // На сервере есть авторизация и через token мы понимаем userID, поэтому берем его из контекста
  const {token} = useContext(AuthContext)

  // Запросы на сервер делаем через наш hook,
  // оттуда же берем loading, чтобы знать когда запрос завершится
  const {request, loading} = useHTTP()

  // Функция запроса на сервер
  // Чтобы не было рекурсии используем useCallback
  const getLinks = useCallback(async () => {
    try{
      const links = await request('../api/link', "GET", null, {
        authorization: `Bearer ${token}`
      })
      // По звершении устанавливаем локальный стейт
      setLinks(links)
    } catch (e) {}
  }, [token, request])

  useEffect(() => {
    // Когда компонент создался - запускаем функцию запроса на сервер
    getLinks()
  },[getLinks])

  // Пока грузится запрос на сервер - показываем Loader
  // Как только Loading завершится - обновится state и компонент пересоздасться
  if(loading){
    return <Loader/>
  }

  return (
    <div>
      <h1>Links</h1>
      <LinksList links={links} />
    </div>

  )
}