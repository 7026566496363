import React, {useCallback, useContext, useEffect, useState} from "react"
import {useParams} from 'react-router-dom'
import {useHTTP} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import {Loader} from "../components/Loader";
import {LinkCard} from "../components/LinkCard";

export const DetailPage = () => {
  const [link, setLink] = useState(null)
  // На назвали наш параметр id, потому что мы сами его назвали Id в routes <Route path="/detail/:id">
  const linkID = useParams().id

  // Отправляем запрос на сервер с помощью нашего hook useHttp, чтобы получить данные ссылки с помощью ее id

  const {request, loading} = useHTTP()

  // Поскольку на сервере от нас ждут авторизацию, то используем AuthContext, чтобы получить jvt token
  const {token} = useContext(AuthContext);

  // Метод запроса ссылки с сервера.
  // Поскольку есть запрос, то используем async / await
  // Чтобы ошибки не крашили сервер - try / catch(e).
  // useCallback чтобы React не входил в рекурсию
  // все внешние переменные и методы отправляем в зависимости
  const getLink = useCallback(async () => {
    try {
      const fetched = await request(`../api/link/${linkID}`, "GET", null, {
        authorization: `Bearer ${token}`
      })
      // Получаем с сервера ссылку и отправляем в наш локальный стейт
      setLink(fetched)
    }catch (e) {}

  }, [request, linkID, token])

  // Когда компонент сформирован, отправляем запрос на сервер
  useEffect(() => {
    getLink()
  },[getLink])

  // Очень хороший подход.
  // Мы в запросе (хук useHTTP) делали loading, чтобы избежать всех этих проверок в компонентах, теперь мы просто экспортировли Loading и пока он true показываем loader
  // как только он меняет статус, то компонент перезагружается. Компонент может перезагрузиться 3-4 раза, но в итоге выдает что нужно
  if(loading){
    return (
      <Loader />
    )
  }

  // Если loading завершился и link присутствует, то грузим компонент
  return (
    <div>
      {!loading && link && <LinkCard link={link} />}
    </div>
  )
}