import {useCallback} from 'react'

export const ShowMessage = () => {
  // использование useCallback избавляет нас от рекурсии, когда ошибка появляется 1 раз, потом 2 раза, потом 4, потом 8
  return useCallback((error) => {
    if(!!window.M && !!error){
      //M.toast({ html: text }) - Это всплывахи materialise https://materializecss.com/toasts.html
      window.M.toast({html: error})
    }
  }, [])

}