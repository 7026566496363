import React from "react"
import {Routes} from "./routes";
import {BrowserRouter as Router} from "react-router-dom"
import {AuthContext} from "./context/AuthContext"
import {LoginHook} from "./hooks/login.hook";
import {Navbar} from "./components/Navbar";
import {Loader} from "./components/Loader";
// Внутри этого файла подключены materialize.css зи node_modules
import "./index.scss"



function App() {

  const {token, userId, login, logout, ready} = LoginHook();
  // Если пользователю присвоен token, то он авторизован
  const isAuthenticated = !!token;

  // Если авторизация еще не отработала, то грузим компонент loader, а если отработала, то переходим к роутам
  if (!ready) {
    return (
      <Loader/>
    )
  }

  return (
    <AuthContext.Provider value={{
      token, userId, login, logout, isAuthenticated, ready
    }}>
      <Router>
        {isAuthenticated && <Navbar/>}
        <div className="container">
          {/*Набор роутов определяется в это компоненте*/}
          <Routes isAuth={isAuthenticated}/>
        </div>
      </Router>
    </AuthContext.Provider>

  );
}

export default App;
